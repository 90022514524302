<template>
  <div class="container--fluid pa-0">
    <the-header />
    <div class="container--fluid pa-0">
      <v-card flat color="primary" height="300" class="d-flex">
        <v-card
          flat
          color="secondary"
          height="89"
          class="border-r-0 ma-auto d-flex align-center justify-center px-10 py-4"
          width="375"
        >
          <p
            class="mb-0 font-40 line-h-62 font-weight--bold white--text letter-s-25 font-sofia"
          >
            Login
          </p>
        </v-card>
      </v-card>
      <v-container class="pt-52 pb-207">
        <v-card flat color="transparent" width="560" class="border-r-0 ma-auto">
          <v-form class="px-10 py-8" ref="form">
            <div class="d-flex mb-2">
              <v-text-field
                v-model="form.email"
                :rules="rules.email"
                hide-details="auto"
                autocomplete="off"
                type="email"
                flat
                solo
                outlined
                class="form-input border-r-0"
                placeholder="Email Address*"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                    >mdi-email-outline</v-icon
                  >
                </template>
              </v-text-field>
            </div>
            <div class="d-flex mb-2">
              <v-text-field
                v-model="form.password"
                :rules="rules.password"
                hide-details="auto"
                autocomplete="off"
                type="password"
                flat
                solo
                outlined
                class="form-input border-r-0"
                placeholder="Password*"
              >
                <template v-slot:prepend-inner>
                  <img
                    class="mr-3 ml-5 mt-1"
                    src="@/assets/images/ri_lock-password-line.png"
                  />
                </template>
              </v-text-field>
            </div>
            <div class="d-flex justify-end mb-2">
              <router-link to="/password/reset">
                <a
                  class="font-16 line-h-25 font-weight--regular text-color-secondary-200"
                >
                  Forgot Password?
                </a>
              </router-link>
            </div>
            <div class="d-flex justify-end mt-6">
              <v-btn
                :ripple="false"
                color="transparent"
                class="sell-your-ticket-submit height-59 width-166 btn-primary text-capitalize font-20 line-h-20 font-weight--bold letter-s-0 text-color-secondary-100 border-r-0"
                elevation="0"
                @click="doLogin"
                :loading="loading"
              >
                Login
                <img
                  class="ml-2 mt-1"
                  src="@/assets/images/right-arrow.svg"
                  alt=""
                />
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-container>
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheFooter from "../components/TheFooter.vue";
import TheHeader from "../components/TheHeader.vue";

export default {
  name: "Login",
  components: { TheHeader, TheFooter },
  data: () => ({
    loading: false,
    form: {
      email: null,
      password: null,
    },
    rules: {
      email: [
        (v) => !!v || "Please enter email address",
        (v) =>
          /.+@.+\..+/.test(v) ||
          "Please enter a valid email address e.g. example@example.com",
      ],
      password: [(v) => !!v || "Please enter password"],
    },
  }),
  methods: {
    async doLogin() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        await this.$store.dispatch("auth/LOGIN", this.form).catch((e) => {
          alert(e.response.data.message);
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style></style>
